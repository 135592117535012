import React from "react"
import { Link, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = (props) => (
      <Layout>
        <SEO description="BuilderCover is the services concierge to building developers and owners. According to your project, we put our clients in touch with the highest performing vendors across many service industries."
        title="Construction Industry Concierge" 
        keywords={[`construction`, `building services`, `engineering`, `construction insurance`, `construction finance`]} />
        <section className="hero is-medium" >
          <BackgroundImage
            fluid={props.data.indexImage.childImageSharp.fluid}
          >
            <div className="hero-body">
              <div className="container">
                <div className="columns">
                  <div className="column is-1 is-hidden-touch">
                  </div>
                  <div className="column is-8">
                    <h1 className="title has-text-white is-size-1-desktop is-size-2-touch has-text-weight-bold">
                      Go Build Something Great. We've Got You Covered.
                    </h1>
                    <h2 className="subtitle has-text-white is-size-5">
                      BuilderCover is your construction industry concierge. Our nationwide network 
                      of service companies <span className="is-italic has-text-weight-bold">- spanning construction finance, engineering, general contracting, insurance,
                      and others -</span> will ensure that your next project is successful.
                    </h2>
                    <Link to="/request">
                      <button className="button is-link is-medium">
                        Request Services
                      </button>
                    </Link>
                  </div>  
                </div>
              </div>
            </div>
          </BackgroundImage>
        </section>
        <section className="section container">
          <div className="columns">
            <div className="column column is-10 is-offset-1">
              <h1 className="title is-uppercase has-text-centered">Get The Best Vendors for your construction project</h1>
              <h2 className="subtitle">
                Stop wasting time looking for service providers and focus on building. BuilderCover will analyze
                your project, determine the vendors best suited to your specific situation, and direct them to you. Our service is completely free to project
                owners, builders, and developers.
              </h2>
            </div>
          </div>   
          <div className="columns container">
            <div className="column">
              <div className="box has-text-centered">
                <Img fixed={props.data.shakeImage.childImageSharp.fixed}/>
                <h3 className="title is-size-5 is-uppercase">One-to-one Matching</h3>
                <p className="has-text-left">
                  Never get innundated with unsolicited phone calls. We set you up with one vendor of the requested service unless explicitly directed. Contact on your schedule.
                </p>
              </div>
            </div>
            <div className="column">
              <div className="box has-text-centered">
                <Img fixed={props.data.plansImage.childImageSharp.fixed}/>
                <h3 className="title is-size-5 is-uppercase">Local Expertise</h3>
                <p className="has-text-left">
                  Our nationwide network of service providers is always geographically filtered by your area. We understand the importance of local knowledge in construction.
                </p>
              </div>
            </div>
            <div className="column">
              <div className="box has-text-centered">
                <Img fixed={props.data.pourImage.childImageSharp.fixed}/>
                <h3 className="title is-size-5 is-uppercase">Project Specific Vendors</h3>
                <p className="has-text-left">
                  Including specialty projects, BuilderCover analyzes the details of your project to provide you with service vendors that have succeeded in similar circumstances.
                </p>
              </div>
            </div>
          </div>
          <br/>
          <br/>   
          <div className="section container has-text-centered is-paddingless">
            <Link to="/services" className="button is-primary is-rounded is-large">View Available Services</Link>
          </div>
          <br/>
        </section>
        <section className="section has-background-light">
          <div className="container">
            <div className="columns">
              <div className="container column is-10 is-offset-1">
                <h1 className="title is-uppercase has-text-centered ">How We Match</h1>
                <h2 className="subtitle">
                  BuilderCover utilizes a proprietary matching system to find the best service vendors for your construction project. We analyze
                  project details against several vendor data points. Our system determines which vendor has successfully delivered in similar situations,
                  alleviating the unknown of many project owners and builders. There are <span className="has-text-weight-bold">three primary filters</span> at BuilderCover for finding the best people
                  to keep you on budget and building.
                </h2>
              </div>  
            </div>
            <div className="columns">
              <div className="column is-three-fifths is-vcentered">
                <h2 className="is-size-1-desktop is-size-2-touch">1. Geography</h2>
                <h2 className="is-size-1-desktop is-size-2-touch">2. Public Data Sources</h2>
                <h2 className="is-size-1-desktop is-size-2-touch">3. Vendor History</h2>
              </div>
              <div className="column is-vcentered">
                <Img fluid={props.data.filterImage.childImageSharp.fluid}/>
              </div>
            </div>
          </div>
        </section>
        <section className="section container">
          <div className="columns container">
            <div className="column">
            </div>
            <div className="column is-four-fifths">
              <h1 className="title is-size-1-desktop is-size-2-touch has-text-centered">Let BuilderCover Power Your Next Project</h1>
            </div>
            <div className="column">
            </div>
          </div>
        </section>
        
      </Layout>
    )

export default IndexPage

export const pageQuery = graphql `
  query {
    indexImage: file(relativePath: { eq: "work-hero.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 2800, quality: 40) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shakeImage: file(relativePath: { eq: "handshake.jpg"}) {
      childImageSharp {
        fixed(width: 200, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    plansImage: file(relativePath: { eq: "plans.jpg"}) {
      childImageSharp {
        fixed(width: 200, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    pourImage: file(relativePath: { eq: "concretepour.jpg"}) {
      childImageSharp {
        fixed(width: 200, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    filterImage: file(relativePath: { eq: "filters.png"}) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 40) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;